import graphql__c79a99e15b10fa93a2ae from "./__generated__/UserManagementLayoutQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import { Container } from 'components/layout'
import { useApplyTenant } from 'components/tenant'
import { useAppContext } from 'context'
import paths from 'paths'
import React, { Fragment, Suspense, useEffect, useMemo } from 'react'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import { UserManagementLayoutQuery } from './__generated__/UserManagementLayoutQuery.graphql'
import UserManagementHeader from './UserManagementHeader'
import UserManagementNavigation from './UserManagementNavigation'

export const selfPaths = [
  paths.userApps({}),
  paths.userOrganizations({}),
  paths.userTenant({ area: 'employee-groups' }),
  paths.settings(),
]

export const managementPaths = (userId: string) => [
  paths.userApps({ userId }),
  paths.userOrganizations({ userId }),
  paths.userTenant({ userId, area: 'employee-groups' }),
]

const query = graphql__c79a99e15b10fa93a2ae

export default function UserManagementLayoutContainer() {
  const { userId } = useParams<{ userId: string }>()

  const [queryRef, loadQuery] = useQueryLoader<UserManagementLayoutQuery>(query)

  useEffect(() => {
    const skipMe = !!userId
    const skipUser = !userId
    loadQuery(
      {
        userId: userId || '',
        skipMe,
        skipUser,
      },
      { fetchPolicy: 'store-and-network' },
    )
  }, [loadQuery, userId])

  if (!queryRef) {
    return null
  }

  return <UserManagementLayout queryRef={queryRef} />
}

type Props = {
  queryRef: PreloadedQuery<UserManagementLayoutQuery>
}
function UserManagementLayout({ queryRef }: Props) {
  const location = useLocation()
  const { userId } = useParams<{ userId: string }>()

  const { isSuperAdmin } = useAppContext()
  const { viewer } = usePreloadedQuery(query, queryRef)

  useApplyTenant(null)

  // Replace the trailing slash with an empty string, if the path is not the root.
  // This is to prevent the layout from being hidden e.g. on /settings/ or /apps/
  const adjustedPath = useMemo(
    () =>
      location.pathname === '/'
        ? location.pathname
        : location.pathname.replace(/\/$/, ''),
    [location.pathname],
  )
  const showUserManagement = useMemo(() => {
    const validPaths = [
      ...selfPaths,
      ...(userId ? managementPaths(userId) : []),
    ]
    return validPaths.includes(adjustedPath)
  }, [adjustedPath, userId])

  const hasSomeUser = !!viewer.me || !!viewer.user
  const someUserIsSuperAdmin =
    viewer.me?.isSuperAdmin || viewer.user?.isSuperAdmin || false
  const OutletContainer = showUserManagement ? Container : Fragment
  const containerProps = showUserManagement ? { className: 'py-8' } : {}

  const showEmployeeGroups = isSuperAdmin && someUserIsSuperAdmin

  return (
    <>
      {showUserManagement && (
        <>
          {hasSomeUser && (
            <UserManagementHeader user={(viewer.me ?? viewer.user)!} />
          )}
          <UserManagementNavigation showEmployeeGroups={showEmployeeGroups} />
        </>
      )}
      <Suspense fallback={null} data-testid="userManagementLayoutSuspense">
        <OutletContainer {...containerProps}>
          <Outlet />
        </OutletContainer>
      </Suspense>
    </>
  )
}
