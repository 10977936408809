import graphql__2ecdc7c2822b5a8ce29e from "./__generated__/OrganizationsMenuItemQuery.graphql.ts";
import graphql__b3423c4eb698bad32a8d from "./__generated__/OrganizationsMenuItem_viewer.graphql.ts";import { Menu } from '@headlessui/react'
import { icons } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks'

import { OrganizationsMenuItem_viewer$key } from './__generated__/OrganizationsMenuItem_viewer.graphql'
import { OrganizationsMenuItemQuery } from './__generated__/OrganizationsMenuItemQuery.graphql'
import { OrganizationsRefetchQuery } from './__generated__/OrganizationsRefetchQuery.graphql'
import MoreOrganizationsMenuItem from './MoreOrganizationsMenuItem'

const query = graphql__2ecdc7c2822b5a8ce29e

const refetchableOrganizations = graphql__b3423c4eb698bad32a8d

const { User, Team, ChevronLeft } = icons

type Props = {
  /** True if the menu item is open */
  isOpen: boolean
  /** Callback for when the tenant is changed */
  onChangeTenant: (tenant: string | null) => void
  /** Callback for when the organizations list isn't rendered. This doesn't actually
   *  run during the close the parent menu, or on unmount of this component.
   */
  onClose: () => void
}

export default function OrganizationsMenuItem({
  isOpen,
  onChangeTenant,
  onClose,
}: Props) {
  const { viewer } = useLazyLoadQuery<OrganizationsMenuItemQuery>(query, {})
  const [data, refetch] = useRefetchableFragment<
    OrganizationsRefetchQuery,
    OrganizationsMenuItem_viewer$key
  >(refetchableOrganizations, viewer)

  useEffect(() => {
    if (isOpen) {
      refetch({}, { fetchPolicy: 'store-and-network' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const renderOrganizations = () => {
    const personal = (
      <TenantMenuButton
        name="Personal"
        onClick={() => onChangeTenant(null)}
        isPersonal
      />
    )

    const organizations = data.me?.organizations
    if (!organizations?.length) {
      return <div className="pb-2">{personal}</div>
    }

    return (
      <>
        <div className="pb-2">
          <span className="block px-4 pb-1 pt-2 text-sm text-foreground-subtle">
            Personal
          </span>
          {personal}
        </div>
        <div className="pb-2">
          <span className="block px-4 pb-1 pt-2 text-sm text-foreground-subtle">
            Teams
          </span>
          {organizations.map((org) => (
            <TenantMenuButton
              key={org.id}
              name={org.name}
              onClick={() => onChangeTenant(org.slug)}
            />
          ))}
        </div>
      </>
    )
  }

  if (!isOpen) {
    return null
  }

  return (
    <>
      <Menu.Item>
        {({ active }) => (
          <button
            data-testid="organizationMenuItemBackButton"
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
            className={classNames(
              'group flex h-12 w-full items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
              { 'bg-background-overlay': !active },
              { 'bg-background-overlay-hovered': active },
            )}
          >
            <span className="flex items-center">
              <ChevronLeft
                className={classNames(
                  'mr-2 h-4 w-4 group-hover:text-foreground-normal',
                  {
                    'text-foreground-normal': active,
                    'text-foreground-subtle': !active,
                  },
                )}
              />
              <span>Back</span>
            </span>
          </button>
        )}
      </Menu.Item>
      {renderOrganizations()}

      <div className="border-border-normal border-t pt-2">
        <MoreOrganizationsMenuItem />
      </div>
    </>
  )
}

type TenantMenuButtonProps = {
  name: string
  onClick: () => void
  isPersonal?: boolean
}
function TenantMenuButton({
  name,
  onClick,
  isPersonal = false,
}: TenantMenuButtonProps) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          data-testid="switchTenantButton"
          onClick={onClick}
          className={classNames(
            'group flex h-12 w-full items-center px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
            { 'bg-background-overlay': !active },
            { 'bg-background-overlay-hovered': active },
          )}
        >
          {isPersonal ? (
            <User
              className={classNames(
                'mr-2 h-4 w-4 group-hover:text-foreground-normal',
                {
                  'text-foreground-normal': active,
                  'text-foreground-subtle': !active,
                },
              )}
            />
          ) : (
            <Team
              className={classNames(
                'mr-2 h-4 w-4 group-hover:text-foreground-normal',
                {
                  'text-foreground-normal': active,
                  'text-foreground-subtle': !active,
                },
              )}
            />
          )}
          <span className="truncate whitespace-nowrap">{name}</span>
        </button>
      )}
    </Menu.Item>
  )
}
