import graphql__0458e9e04147424efbde from "./__generated__/NavigationBarQuery.graphql.ts";import { DittoLogo } from '@portal/components'
import { RawLink } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import { Container } from 'components/layout'
import { TenantContext } from 'components/tenant'
import paths from 'paths'
import React, { useContext } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'

import { NavigationBarQuery } from './__generated__/NavigationBarQuery.graphql'
import AppTenantMenu from './AppTenantMenu'
import UserMenu from './UserMenu'

const query = graphql__0458e9e04147424efbde

const Level = ({ ...props }) => (
  <svg
    width="16"
    height="48"
    viewBox="0 0 16 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.5 16H12L5.5 32H4L10.5 16Z" fill="currentcolor" />
  </svg>
)

type Props = {
  showLinks?: boolean
}

const navLinkClass =
  'flex h-12 items-center px-3 text-base text-foreground-subtle outline-none hover:text-foreground-normal focus-visible:text-foreground-normal focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-focus-outline'

/** Main navigation bar shown on the portal page. */
export default function NavigationBar({ showLinks }: Props) {
  const { tenantHome } = useContext(TenantContext)
  const { viewer } = useLazyLoadQuery<NavigationBarQuery>(
    query,
    {},
    { fetchPolicy: 'store-and-network' },
  )
  return (
    <nav className="fixed top-0 z-10 w-screen bg-background backdrop-blur">
      <Container className="relative flex h-12 justify-between bg-background sm:static">
        <div className="flex min-w-0 flex-auto">
          <div className="flex max-w-full">
            <RawLink
              to={tenantHome || paths.gettingStarted()}
              className="flex h-12 w-6 min-w-0 flex-none items-center justify-center outline-none focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-focus-outline"
            >
              <DittoLogo className="block h-4 w-4 text-foreground-normal sm:h-6 sm:w-6" />
            </RawLink>
            {showLinks && (
              <>
                <Level className="text-border-normal flex-none sm:ml-4" />
                <AppTenantMenu />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-none">
          <div className="hidden sm:flex">
            <RawLink href={paths.dittoDocs()} className={navLinkClass} isBlank>
              Docs
            </RawLink>
            {!!viewer.me?.hubspotContactId && (
              <RawLink to={paths.feedback()} className={navLinkClass}>
                Feedback
              </RawLink>
            )}
            {/* TODO(aaron): Once we have a global changelog again uncomment this  */}
            {/* <RawLink
              href={paths.dittoDocs('changelog')}
              className={navLinkClass}
              isBlank
            >
              Changelog
            </RawLink> */}
            <RawLink href={paths.support()} className={navLinkClass} isBlank>
              Support
            </RawLink>
          </div>
          <UserMenu showLinks={showLinks} />
        </div>
      </Container>
    </nav>
  )
}

NavigationBar.defaultProps = {
  showLinks: true,
}
