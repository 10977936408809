import graphql__9b53d7ef6419b65b750f from "./__generated__/OrganizationAppsMenuItemQuery.graphql.ts";
import graphql__9e2ba0822015a304e0ac from "./__generated__/OrganizationAppsMenuItem_viewer.graphql.ts";import { Menu } from '@headlessui/react'
import { RawLink } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import paths from 'paths'
import React, { useEffect } from 'react'
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks'

import { OrganizationAppsMenuItem_viewer$key } from './__generated__/OrganizationAppsMenuItem_viewer.graphql'
import { OrganizationAppsMenuItemQuery } from './__generated__/OrganizationAppsMenuItemQuery.graphql'
import { ViewerAppsRefetchQuery } from './__generated__/ViewerAppsRefetchQuery.graphql'

const query = graphql__9b53d7ef6419b65b750f

const refetchableApps = graphql__9e2ba0822015a304e0ac

type Props = {
  /** True if the menu item is open */
  isOpen: boolean
  slug: string
}

export default function OrganizationAppsMenuItem({ isOpen, slug }: Props) {
  const { viewer } = useLazyLoadQuery<OrganizationAppsMenuItemQuery>(query, {
    slug,
  })
  const [data, refetch] = useRefetchableFragment<
    ViewerAppsRefetchQuery,
    OrganizationAppsMenuItem_viewer$key
  >(refetchableApps, viewer)

  useEffect(() => {
    if (isOpen) {
      refetch({}, { fetchPolicy: 'store-and-network' })
    }
  }, [refetch, isOpen])

  return (
    <div className="pb-2">
      {data.organization?.apps?.map((orgApp) => (
        <Menu.Item key={orgApp.id}>
          {({ active }) => (
            <RawLink
              to={paths.app({
                organizationSlug: slug,
                appSlug: orgApp.slug,
              })}
              className={classNames(
                'group flex h-12 w-full items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
                { 'bg-background-overlay': !active },
                { 'bg-background-overlay-hovered': active },
              )}
            >
              <span className="truncate whitespace-nowrap">{orgApp.name}</span>
            </RawLink>
          )}
        </Menu.Item>
      ))}
      {!data.organization?.apps?.length && (
        <span className="flex h-12 items-center bg-background-overlay px-4 text-base text-foreground-subtle">
          No organization apps found
        </span>
      )}
    </div>
  )
}
