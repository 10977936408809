import graphql__8396e681712929aa20cd from "./__generated__/UserAppsRouterQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import { useAppContext } from 'context'
import React, { Suspense, useEffect } from 'react'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { UserAppsRouterQuery } from './__generated__/UserAppsRouterQuery.graphql'

const CreateUserAccessGrantScene = React.lazy(
  () => import('./CreateUserAccessGrantScene'),
)
const UserAppsScene = React.lazy(() => import('./UserAppsScene'))

const query = graphql__8396e681712929aa20cd

export default function UserAppsRouterContainer() {
  const { userId } = useParams<{ userId?: string }>()

  const [queryRef, loadQuery] = useQueryLoader<UserAppsRouterQuery>(query)

  useEffect(() => {
    loadQuery(
      {
        userId: userId || '',
        skipMe: !!userId,
        skipUser: !userId,
      },
      {
        fetchPolicy: 'store-and-network',
      },
    )
  }, [userId, loadQuery])

  if (!queryRef) return null

  return (
    <Suspense fallback={null}>
      <UserAppsRouter queryRef={queryRef} />
    </Suspense>
  )
}

type Props = {
  queryRef: PreloadedQuery<UserAppsRouterQuery>
}

function UserAppsRouter({ queryRef }: Props) {
  const { isSuperAdmin } = useAppContext()

  const { viewer } = usePreloadedQuery(query, queryRef)

  const user = viewer.me || viewer.user
  const hasDedicatedApp = user?.hasDedicatedApp || false

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          path=""
          element={<UserAppsScene showGrantRequests={hasDedicatedApp} />}
        />
        {/* TODO(aaron): eventually we will support customers creating grant requests, however it is only for employees for now */}
        {isSuperAdmin && hasDedicatedApp && (
          <Route
            path="request-access"
            element={<CreateUserAccessGrantScene />}
          />
        )}
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </Suspense>
  )
}
